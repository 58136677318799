@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Oswald:wght@200..700&display=swap");

body {
  width: 100vw;
  min-height: 100vh;
  height: 100vh;
  background: #020010;
  font-family: "oswald", cursive;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  font-weight: 650;
  font-size: large;
}

body,
html {
  scroll-behavior: smooth;
}

.head {
  font-family: "Dancing Script", cursive;
  font-optical-sizing: auto;
}
